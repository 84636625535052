import { render, staticRenderFns } from "./footerPc.vue?vue&type=template&id=7fd2ecbf&scoped=true&"
import script from "./footerPc.vue?vue&type=script&lang=js&"
export * from "./footerPc.vue?vue&type=script&lang=js&"
import style0 from "./footerPc.vue?vue&type=style&index=0&id=7fd2ecbf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fd2ecbf",
  null
  
)

export default component.exports