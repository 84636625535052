<template>
  <footer class="xm-footer">
    <!-- ie svg 来处理高斯模糊 -->
    <!-- <div class="blur-content">
      <svg version="1.1" 
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        xmlns:ev="http://www.w3.org/2001/xml-events"     
        baseProfile="full">
        <defs>
            <filter id="blur">
                <feGaussianBlur stdDeviation="10" />
            </filter>
        </defs>
        <image xlink:href="mm1.jpg" x="0" y="0" height="191" width="265" filter="url(#blur)" />
      </svg>
    </div> -->
    <div class="xm-footer-top">
      <img
        class="xm-footer-top-logo"
        :src="require('../../../assets/logo.png')"
        alt=""
      />
      <!-- <span class="xm-footer-top-phone-font ifPc">商务洽谈：yitaihaoxi@163.com</span>
      <span class="xm-footer-top-phone-font ifPc">合作推广：yitaihaoxi@163.com</span>
      <span class="xm-footer-top-phone-font ifPc">加入我们：yitaihaoxi@163.com</span> -->
      <div class="xm-footer-top-information ifPhone">
        <div class="xm-footer-top-list xm-footer-top-about">
          <span class="xm-footer-top-list-title">联系我们</span>
          <span class="xm-footer-top-list-span"
            >商务洽谈：yitaihaoxi@163.com</span
          >
          <span class="xm-footer-top-list-span"
            >合作推广：yitaihaoxi@163.com</span
          >
          <span class="xm-footer-top-list-span"
            >加入我们：yitaihaoxi@163.com</span
          >
        </div>
        <div class="xm-footer-top-list">
          <span class="xm-footer-top-list-title">关于我们</span>
          <span
            class="xm-footer-top-list-span"
            @click="handleSpanLocation('公司简介')"
            >公司简介</span
          >
          <span
            class="xm-footer-top-list-span"
            @click="handleSpanLocation('管理团队')"
            >管理团队</span
          >
          <span
            class="xm-footer-top-list-span"
            @click="handleSpanLocation('企业文化')"
            >企业文化</span
          >
          <span
            class="xm-footer-top-list-span"
            @click="handleSpanLocation('联系我们')"
            >联系我们</span
          >
        </div>
        <div class="xm-footer-top-list xm-footer-top-develope">
          <span class="xm-footer-top-list-title">员工发展</span>
          <span
            class="xm-footer-top-list-span"
            @click="handleSpanLocation('人才培育')"
            >人才培育</span
          >
          <span
            class="xm-footer-top-list-span"
            @click="handleSpanLocation('工作环境')"
            >工作环境</span
          >
          <span
            class="xm-footer-top-list-span"
            @click="handleSpanLocation('员工活动')"
            >员工活动</span
          >
        </div>
        <div class="xm-footer-top-list xm-footer-top-develope">
          <span class="xm-footer-top-list-title">加入我们</span>
          <span
            class="xm-footer-top-list-span"
            @click="handleSpanLocation('招聘岗位')"
            >招聘岗位</span
          >
        </div>
      </div>
    </div>
    <div class="xm-footer-bottom">
      <div class="xm-footer-bottom-font">
        <span class="xm-footer-span big"
          ><a href="https://beian.miit.gov.cn/" target="_blank"
            >津ICP备2024015367号-2</a
          >｜ ICP经营许可证：<a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >津ICP备2024015367号</a
          >｜天津一台好戏文化传媒有限公司 版权所有｜Copyright © 2024 Yitaihaoxi.
          All Rights Reserved.</span
        >
        <div class="xm-footer-span big">
          公司地址：天津市河东区创智大厦1-2316
        </div>
        <span class="xm-footer-span small"
          ><a href="https://beian.miit.gov.cn/" target="_blank"
            >津ICP备2024015367号-2</a
          >｜ ICP经营许可证：<a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            >津ICP备2024015367号</a
          >｜天津一台好戏文化传媒有限公司 版权所有｜Copyright © 2024 Yitaihaoxi.
          All Rights Reserved.</span
        >
        <br />
        <div class="xm-footer-span small">
          公司地址：天津市河东区创智大厦1-2316
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import Config from 'utils/Config';
export default {
  name: 'footerPc',
  data() {
    return {};
  },
  methods: {
    handleSpanLocation: function (name) {
      let urlArray = {
        公司简介: '/about.html#aboutIntro',
        管理团队: '/about.html#aboutManage',
        企业文化: '/about.html#aboutCulture',
        联系我们: '/about.html#aboutContact',
        人才培育: '/develop.html#developFoster',
        工作环境: '/develop.html#developEnv',
        员工活动: '/develop.html#developActivity',
        招聘岗位: '/hire.html#hireFuture'
      };
      // window.location.href = window.location.origin + urlArray[name];
      window.location.href = Config.domain + urlArray[name];
    }
  },
  components: {}
};
</script>

<style lang="scss" scoped>
.xm-footer {
  width: 100%;
  overflow: hidden;
  // height: 330px;
  background: rgba(255, 255, 255, 0.4);
  box-shadow: 0px 0px 0px 0px rgba(225, 225, 225, 1);
  border-radius: 2px;
  position: relative;
  z-index: 100;
  border-top: 1px solid #e1e1e1;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    backdrop-filter: blur(5px);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .blur-content {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 99;
  }
}

.xm-footer-top {
  width: 1200px;
  // padding: 0 120px;
  margin: 0 auto;
  // width: 100%;
  // height: 285px;
  display: flex;
  justify-content: space-between;
  background: rgba(255, 255, 255, 0.3);
  box-sizing: border-box;
  // padding: 0 85px;
  position: relative;
  z-index: 1;
}

.xm-footer-top-logo {
  width: 155px;
  height: 45px;
  margin-top: 60px;
}

.xm-footer-top-information {
  // height: 1px;
  margin-top: 60px;
  margin-bottom: 60px;
  display: flex;
}

.xm-footer-top-list {
  display: flex;
  flex-direction: column;
}

.xm-footer-top-list-title {
  display: block;
  font-size: 17px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: bold;
  color: #222222;
  margin-bottom: 24px;
}

.xm-footer-top-list-span {
  display: block;
  font-size: 14px;
  margin-bottom: 9px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  height: 24px;
  line-height: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.xm-footer-top-list-span:hover {
  cursor: pointer;
  color: #3292ff;
}

.xm-footer-top-about {
  margin-left: 86px;
  margin-right: 75px;
}

.xm-footer-top-develope {
  margin-left: 75px;
}

.xm-footer-bottom {
  width: 100%;
  // height: 45px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fbfbfb;
  position: relative;
  z-index: 1;
  padding: 20px 0;
}

.xm-footer-bottom-font {
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: 15px;
  // line-height: 15px;
}

.xm-footer-span {
  font-size: 11px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #222222;
  opacity: 0.6;
  margin-bottom: 5px;
  a {
    color: #1b73e8;
    opacity: 1;
  }
}
.xm-footer-bottom:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.3);
}
.small {
  display: none;
}

// 适配其他尺寸
@media screen and (max-width: 1200px) {
  .big {
    display: none;
  }
  .small {
    text-align: center;
    display: block;
    font-size: 11px;
    line-height: 17px;
  }

  .xm-footer-bottom {
    padding: 23px 0;
  }
  .xm-footer-top {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding: 95px 0 70px;
    // width: 815px;
    width: 100%;
    box-sizing: border-box;
    padding: 80px 30px;
  }
  .xm-footer-top-about {
    margin: 0;
  }
  .xm-footer-top-information {
    width: 100%;
    justify-content: space-between;
    margin-top: 0;
    margin-bottom: 0;
  }

  .xm-footer-top-logo {
    display: none;
  }

  .xm-footer-top-develope {
    margin-left: 0;
  }
  .xm-footer-top-list-title {
    font-size: 18px;
    margin-bottom: 24px;
  }
  .xm-footer-top-list-span {
    font-size: 14px;
  }
}

// @media screen and  (max-width: 900px) {
//   .xm-footer-top{
//     width: 648px;
//   }
// }
</style>
